var transitionDelay;
if (screenWidth > 991) {
	transitionDelay = 1000;
} else {
	transitionDelay = 1000;
}


/* ---------------------------------------------------- */
// Page transition

// ** Page ** //
var PageDefault = Barba.BaseTransition.extend({
	valid: function() {
		var prev = Barba.HistoryManager.prevStatus();
		return prev.namespace === 'pagedefault';
	},

	start: function() {
		joss('Transition-from: pagedefault');

		// As soon as the loading is finished and the old page is faded out, let's fade the new page
		Promise
		.all([this.newContainerLoading, this.slideOut()])
		.then(this.slideIn.bind(this));
	},

	slideOut: function() {
		return new Promise(function(resolve, reject) {
		window.setTimeout(function() {
				resolve();
			}, transitionDelay);
		});
	},

	slideIn: function() {
		window.scrollTo(0, 0);

		this.done();
	}
});