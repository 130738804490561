// **---------------------------------------------------**
// -------------------------------------------------------
// DOMContentLoaded BEGIN
document.addEventListener("DOMContentLoaded", function(event) {
	// init namescapes
	pagedefault.init();
	pagehome.init();

	// init barba
	Barba.Pjax.start();
	Barba.Pjax.getTransition = function() {
		if (!theBody.classList.contains("ajax-transition-begin")) {
			theBody.classList.add("ajax-transition-begin");
		}

		if (PageDefault.valid()) {
			return PageDefault;
		}

		// fallback / default
		return PageDefault;
	};

	// The user click on a link elegible for AJAX.
	Barba.Dispatcher.on("linkClicked", function() {
		console.log("%c" + "---------------------" + "", "color: #D84C4C;");
		console.log("%c" + "CLICKED" + "", "color: #D84C4C;");

		joss("");
	});

	// the new container has been loaded and injected in the wrapper
	Barba.Dispatcher.on("newPageReady", function(
		currentStatus,
		prevStatus,
		HTMLElementContainer,
		newPageRawHTML
	) {
		console.log("%c" + "---------------------" + "", "color: #D84C4C;");
		console.log("%c" + "READY" + "", "color: #D84C4C;");

		ScrollItem.prototype.reset();
		Accordion.prototype.clear();

		isActiveLink();

		joss("");
	});

	// The transition has just finished and the
	// old Container has been removed from the DOM.
	Barba.Dispatcher.on("transitionCompleted", function() {
		console.log("%c" + "---------------------" + "", "color: #D84C4C;");
		console.log("%c" + "COMPLETED" + "", "color: #D84C4C;");

		Scrollbar.getAll()[0].setPosition(0, 0);
		updateLogoColor();

		if (theBody.classList.contains("ajax-transition-begin")) {
			theBody.classList.add("ajax-transition-done");
			theBody.classList.remove("ajax-transition-begin");
		}

		ScrollItem.prototype.init();
		Accordion.prototype.init();

		initAnchors();
		
		setTimeout(function() {
			ScrollItem.prototype.watch();
		}, firstLoadDelay + 150);

		joss("");
	});
});
// DOMContentLoaded END
// -------------------------------------------------------
// **---------------------------------------------------**
