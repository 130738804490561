// **---------------------------------------------------**
// -------------------------------------------------------
// Global variables BEGIN

// store the window.scrollY value
var scrolled_y = 0;

//
var mapLoaded = false;

// requestAnimationFrame
var ticking = false;

// ajax
var firstLoadDelay = 250;

// Scrollbar
var Scrollbar;

// cache some stuff
var theBody = document.getElementsByTagName("body")[0];

// screen size
var screenWidth = window.innerWidth;
var screenHeight = window.innerHeight;

// Global variables END
// **---------------------------------------------------**
// -------------------------------------------------------

// Remember
function joss(msg) {
	console.log(msg);
}

// ** This function is used only when the site FIRST load to have a custom animation (only use when ajax) ** //
function initFirstPageLoad() {
	if (theBody.classList.contains("js-firstLoad")) {
		setTimeout(function() {
			theBody.classList.remove("js-firstLoad");
			updateLogoColor();
		}, firstLoadDelay);

		setTimeout(function() {
			ScrollItem.prototype.watch();
		}, firstLoadDelay + 150);
	}
}

// ** Load Google font(s) async ** //
function loadFonts() {
	WebFontConfig = {
		google: {
			families: ["Roboto:300,400,500,700,900", "Material+Icons"]
		}
	};

	var wf = document.createElement("script");
	wf.src = "https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js";
	wf.type = "text/javascript";
	wf.async = "true";
	var s = document.getElementsByTagName("script")[0];
	s.parentNode.insertBefore(wf, s);
}

// ** G map ** //
function loadGoogleMap() {
	var $gmap_ele = document.querySelector(".Map");
	if ($gmap_ele) {
		if (!mapLoaded) {
			var $scriptEle = document.createElement("script");
			$scriptEle.src =
				"https://maps.googleapis.com/maps/api/js?key=" +
				$gmap_ele.getAttribute("data-api") +
				"&language=" +
				$gmap_ele.getAttribute("data-language") +
				"&callback=initMap";
			$scriptEle.type = "text/javascript";
			$scriptEle.async = "true";
			$scriptEle.setAttribute("id", "googleMap");
			var $scripts = document.getElementsByTagName("script")[0];
			$scripts.parentNode.insertBefore($scriptEle, $scripts);
			mapLoaded = true;
		} else {
			initMap();
		}
	}
}

// ** Load script async ** //
function loadScript() {
	// if you need to init some variable(s) before, they go here
	// var API_nice||{};

	var s1 = document.createElement("script"),
		s0 = document.getElementsByTagName("script")[0];
	s1.async = true;
	s1.src = "yourScriptFileGoesHere.js";
	s1.charset = "UTF-8";
	s1.setAttribute("crossorigin", "*");
	s0.parentNode.insertBefore(s1, s0);
}

// ** Smooth scrollbar ** //
function initScrollbar() {
	elContainer = document.getElementById("js-scroll");
	Scrollbar = window.Scrollbar;
	Scrollbar.init(elContainer);
	ScrollbarState = true;
}

function getScrollInfo(info) {
	return Scrollbar.getAll()[0].info;
}

function getCurrentScrollTop() {
	return Scrollbar.getAll()[0].scrollTop;
}

function currentlyVisible(el) {
	return Scrollbar.getAll()[0].isVisible(el);
}

function getCurrentOffset(axis) {
	if (axis == "y") {
		return Scrollbar.getAll()[0].offset.y;
	}

	return Scrollbar.getAll()[0].offset.x;
}

function initAnchors() {
	var links = document.querySelectorAll('[href^="#"]');

	for (var i = links.length - 1; i >= 0; i--) {
		links[i].addEventListener("click", function(e) {
			var target = e.target.hash.substr(1);
			Scrollbar.getAll()[0].scrollIntoView(document.getElementById(target), {
				offsetTop: (number = 100)
			});
			e.preventDefault;
		});
	}
}

// ** Barba stuff ** //
function getCurrentNamespace() {
	var el = document.getElementsByClassName("barba-container");
	var dataNs = el[0].dataset.namespace;

	return dataNs;
}

function setTheCurrentUrl() {
	curUrl = Barba.Utils.getCurrentUrl();
}

function getTheCurrentUrl() {
	setTheCurrentUrl();
	return curUrl;
}

// update logo color
function updateLogoColor() {
	var navbar = document.getElementById('NavBar');
	var element = document.getElementsByClassName('js-normal-logo')[0];


	if (element) {
		navbar.classList.remove('header__main-logo-white')
		return
	}

	navbar.classList.add('header__main-logo-white');
}

// **---------------------------------------------------**
// -------------------------------------------------------
// DOMContentLoaded BEGIN
document.addEventListener("DOMContentLoaded", function(e) {
	// *******************************
	// Functions that get called once on document ready
	loadFonts();

	// scrollbar
	initScrollbar();
	Scrollbar.getAll()[0].addListener(scrolling);

	// watchscroll
	ScrollItem.prototype.init();

	// page first laod animation
	initFirstPageLoad();

	// Toggle mobile nav
	toggleMenu();

	// accordions
	Accordion.prototype.init();

	// Gmap
	loadGoogleMap();

	initAnchors();
});
// DOMContentLoaded END
// -------------------------------------------------------
// **---------------------------------------------------**

// **---------------------------------------------------**
// -------------------------------------------------------
// on resize BEGIN
window.addEventListener(
	"resize",
	function() {
		screenWidth = window.innerWidth;
		screenHeight = window.innerHeight;
	},
	true
);
// on resize END
// -------------------------------------------------------
// **---------------------------------------------------**

// **---------------------------------------------------**
// -------------------------------------------------------
// on scroll BEGIN
window.addEventListener("scroll", function(e) {
	scrolled_y = window.pageYOffset;

	if (!ticking) {
		window.requestAnimationFrame(function() {
			// place your function(s) here
			ScrollItem.prototype.watch();

			ticking = false;
		});

		ticking = true;
	}
});

/* if you have a custom scrollbar, you need to use this function to get scroll event */
function scrolling() {
	if (ScrollbarState) {
		scrolled_y = getCurrentScrollTop();
	}

	if (!ticking) {
		window.requestAnimationFrame(function() {
			// place your function(s) here
			ScrollItem.prototype.watch();

			ticking = false;
		});

		ticking = true;
	}
}
// on scroll BEGIN
// **---------------------------------------------------**
// -------------------------------------------------------

function toggleMenu() {
	var toggleBtn = document.querySelector("#toggleMenu");
	var dropdown = document.querySelector(".header-mobile__dropdown");

	toggleBtn.addEventListener("click", function() {
		current = toggleBtn.dataset.icon;
		on = "close";
		off = "menu";

		if (current == "menu") {
			toggleBtn.dataset.icon = on;
		} else {
			toggleBtn.dataset.icon = off;
		}

		toggleBtn.classList.toggle("click");
		dropdown.classList.toggle("show");
	});
}

function isActiveLink() {
	var link = window.location.href.split(window.location.origin)[1].substring(1); // get path of current page
	var navigation = document.querySelectorAll(".main-nav-list");

	for (x = 0; x < navigation.length; x++) {
		var navigationLinks = navigation[x].querySelectorAll(".main-nav-item a");
		var navigationLinkIsActive = navigation[x].querySelector(
			"a[href='/" + link + "']"
		);

		// removes CSS class 'is-active' from all .navigation__links
		for (var i = navigationLinks.length - 1; i >= 0; i--) {
			navigationLinks[i].classList.remove("active");
		}

		if (navigationLinkIsActive)
			navigationLinkIsActive.classList.add("active");
	}
}
