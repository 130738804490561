/* ---------------------------------------------------- */
// AJAX namespace

// ** default ** //
var pagedefault = Barba.BaseView.extend({
	namespace: "pagedefault",

	onEnter: function() {
		// The new Container is ready and attached to the DOM.
	},
	onEnterCompleted: function() {
		loadGoogleMap();
		// The Transition has just finished.
		document.querySelector(".header").classList.add("header__main-logo-white");
		var toggleBtn = document.querySelector("#toggleMenu");
		toggleBtn.classList.remove("click");
		// add class on the body to anim the content
		setTimeout(function() {
			theBody.classList.remove("page-transition");
			theBody.classList.add("page-show");
		}, 350);
	},
	onLeave: function() {
		// A new Transition toward a new page has just started.

		setTimeout(function() {
			theBody.classList.remove("page-show");
			theBody.classList.add("page-transition");
		}, 150);
	},
	onLeaveCompleted: function() {
		// The Container has just been removed from the DOM.
		var dropdown = document.querySelector(".header-mobile__dropdown");
		dropdown.classList.remove("show");
		var toggleBtn = document.querySelector("#toggleMenu");
		toggleBtn.dataset.icon = "menu";
	}
});

var pagehome = Barba.BaseView.extend({
	namespace: "pagehome",

	onEnter: function() {
		// The new Container is ready and attached to the DOM.
	},
	onEnterCompleted: function() {
		// The Transition has just finished.
		document.querySelector(".header").classList.add("header__home");
		document
			.querySelector(".header")
			.classList.remove("header__main-logo-white");
		// add class on the body to anim the content
		setTimeout(function() {
			theBody.classList.remove("page-transition");
			theBody.classList.add("page-show");
		}, 350);
	},
	onLeave: function() {
		// A new Transition toward a new page has just started.
		var dropdown = document.querySelector(".header-mobile__dropdown");

		setTimeout(function() {
			dropdown.classList.remove("show");
			theBody.classList.remove("page-show");
			theBody.classList.add("page-transition");
		}, 150);
	},
	onLeaveCompleted: function() {
		var toggleBtn = document.querySelector("#toggleMenu");
		toggleBtn.dataset.icon = "menu";

		// The Container has just been removed from the DOM.
		document.querySelector(".header").classList.remove("header__home");
	}
});
