/*
 * - Ajax ready
 */

// **---------------------------------------------------**
// -------------------------------------------------------
// Global variables BEGIN

var Accordions = [];

// Global variables END
// **---------------------------------------------------**
// -------------------------------------------------------

// **---------------------------------------------------**
// -------------------------------------------------------
// Object BEGIN
function Accordion(_accordion, _children, _collapsible) {
	/*
	 * constructor
	 */
	this.accordion = _accordion;
	this.children = _children;
	this.collapsible = _collapsible;
}
// Object END
// **---------------------------------------------------**
// -------------------------------------------------------

// **---------------------------------------------------**
// -------------------------------------------------------
// Prototype BEGIN
//init
Accordion.prototype.init = function() {
	/*
	 * first we get all the .accordions and update the Accordions array
	 */
	accordions = document.getElementsByClassName("Accordions");

	/*
	 * loop through the array and create an object with all it's properties
	 */
	for (var i = 0; i < accordions.length; i++) {
		a = accordions[i];

		/*
		 * init the empty children array
		 */
		children = [];

		/*
		 * add data
		 */
		a.dataset.parent = i;

		/*
		 * check if is collapsible
		 */
		collapsible = false;
		if (a.classList.contains("is-collapsible")) collapsible = true;

		/*
		 * get all blocks in the accordion
		 */
		block = a.getElementsByClassName("Accordions_block");

		for (var j = 0; j < block.length; j++) {
			/*
			 * init the empty child array
			 */
			children[j] = [];

			/*
			 * get the current item
			 */
			b = block[j];

			/*
			 * add data
			 */
			b.dataset.parent = i;
			b.dataset.child = j;

			/*
			 * get the block's height
			 */
			height = b.scrollHeight;

			/*
			 * get the content height
			 */
			content = b.getElementsByClassName("Accordions_content_inner")[0];
			contentHeight = content.scrollHeight;

			/*
			 * set the div.content height to 0
			 */
			b.getElementsByClassName("Accordions_content")[0].style.height = 0;

			/*
			 * push the infos in the array
			 */
			children[j]["block"] = b;
			children[j]["active"] = false;
			children[j]["height"] = height;
			children[j]["contentHeight"] = contentHeight;

			/*
			 * add an event listener
			 */
			b.addEventListener("click", Accordion_clicked, false);
		}

		/*
		 * create the Accordion object
		 * a: accordions wrapper
		 * children: accordion block
		 */
		obj = new Accordion(a, children, collapsible);

		/*
		 * push the created object in the array
		 */
		Accordions.push(obj);
	}
};

// toggle
Accordion.prototype.toggle = function(p, c) {
	p_index = p;
	c_index = c;

	/*
	 * a: accordion parent
	 * child: child
	 */
	a = Accordions[p_index];
	child = a.children[c_index];

	/*
	 * check if the accordion is collapsable
	 */
	collapsible = a.collapsible;

	/*
	 * check if the clicked child is active
	 */
	active = child.active;

	if (active) {
		Accordion.prototype.hide(p, c);
	} else {
		Accordion.prototype.show(p, c);
	}
};

// show
Accordion.prototype.show = function(p, c) {
	p_index = p;
	c_index = c;
	a = Accordions[p_index];
	child = a.children[c_index];
	block = child.block;
	content = block.getElementsByClassName("Accordions_content")[0];

	content_h = child.contentHeight + 15;
	content.style.height = content_h + "px";
	block.classList.add("is-active");
	child.active = true;
};

// hide
Accordion.prototype.hide = function(p, c) {
	p_index = p;
	c_index = c;

	a = Accordions[p_index];
	child = a.children[c_index];
	block = child.block;
	content = block.getElementsByClassName("Accordions_content")[0];
	content_h = child.contentHeight;

	content.style.height = 0;
	block.classList.remove("is-active");
	child.active = false;
};

// update
Accordion.prototype.update = function() {
	for (var i = 0; i < Accordions.length; i++) {
		a = Accordions[i];
		children = a.children;

		for (var j = 0; j < children.length; j++) {
			child = children[j];
			block = child.block;
			active = child.active;

			content = block.getElementsByClassName("Accordions_content")[0];
			content_inner = content.getElementsByClassName(
				"Accordions_content_inner"
			)[0];
			content_h = content_inner.scrollHeight;

			child.contentHeight = content_h;

			if (active) {
				content.style.height = content_h + "px";
			}
		}
	}
};

// clear
Accordion.prototype.clear = function() {
	for (var i = 0; i < Accordions.length; i++) {
		a = Accordions[i];
		children = a.children;

		for (var j = 0; j < children.length; j++) {
			child = children[j];

			b = child.block;
			b.removeEventListener("click", Accordion_clicked, false);
		}
	}

	Accordions = [];
};

// Prototype END
// **---------------------------------------------------**
// -------------------------------------------------------

// **---------------------------------------------------**
// -------------------------------------------------------
// Function BEGIN
function Accordion_clicked(event) {
	/*
	 * cache elements
	 */
	e = event;
	item = e.srcElement;
	parent = item.parentElement;

	/*
	 * make sure that the parent is always the Accordions_block
	 * (the parent changes depending on where the click is registered)
	 */
	while (!parent.classList.contains("Accordions_block")) {
		parent = parent.parentElement;
	}

	/*
	 * p: parent accordion index
	 * c: clicked child index
	 */
	p = parent.dataset.parent;
	c = parent.dataset.child;

	/*
	 * pass the parent index and child index to Accordion.prototype.toggle
	 * which reroute to the next function (show or hide)
	 * a: accordions wrapper
	 * children: accordion block
	 */
	Accordion.prototype.toggle(p, c);
}

Accordion.prototype.collapseAll = function(child) {
	accordions = document.getElementsByClassName("Accordions_block");
	for (var i = 0; i < accordions.length; i++) {
		accordions[i].classList.remove("is-active");
		content.style.height = "0px";
		child.active = false;
	}
};
// Function END
// **---------------------------------------------------**
// -------------------------------------------------------

/*
 *** Available prototype
 *
 * Accordion.prototype.init();
 * Accordion.prototype.update();
 * Accordion.prototype.clear();
 *
 ** p: parent accordion index
 ** c: c accordion index
 *
 * Accordion.prototype.toggle(p, c);
 * Accordion.prototype.show(p, c);
 * Accordion.prototype.hide(p, c);
 */

/*
 * @TODO
 * - function qui scroll sur l'accordion cliqué
 * - faire un genre d'api nice pour toggler des accordions en cliquant sur d'autres elements. ex: data-accordion="0-3"
 */
